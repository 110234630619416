/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull app'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 6
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/fulltransfer/FullTransfer/implementation/app)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'development' | 'production';

export const ApiKey: Record<Environment, string> = {
  development: '',
  production: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '6',
    branch: 'main',
    source: 'app',
    versionId: '3fdc4800-7223-4fbe-a08c-d9d6145f4b3c'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface ReferralLinkCopiedProperties {
  referralCode: string;
}

export interface TransferCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 1 day, 1 week, 4 weeks, 3 months, 1 year, 2 weeks, 1 month |
   */
  transferExpiresAfter: "1 day" | "1 week" | "4 weeks" | "3 months" | "1 year" | "2 weeks" | "1 month";
  transferId: string;
  transferIsPaid: boolean;
  transferIsPasswordProtected: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | finalized, initialized, expired, archived, processing |
   */
  transferStatus: "finalized" | "initialized" | "expired" | "archived" | "processing";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, link, shop |
   */
  transferType: "email" | "link" | "shop";
}

export interface TransferDownloadedProperties {
  transferId: string;
  transferIsPaid: boolean;
  transferIsPasswordProtected: boolean;
  transferRecipientId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, link, shop |
   */
  transferType: "email" | "link" | "shop";
}

export interface TransferDownloadRequestedProperties {
  transferId: string;
  transferIsPaid: boolean;
  transferIsPasswordProtected: boolean;
  transferRecipientId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | finalized, initialized, expired, archived, processing |
   */
  transferStatus: "finalized" | "initialized" | "expired" | "archived" | "processing";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, link, shop |
   */
  transferType: "email" | "link" | "shop";
}

export interface TransferPaymentRequestedProperties {
  transferId: string;
  transferRecipientId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, link, shop |
   */
  transferType: "email" | "link" | "shop";
}

export interface TransferSenderVerifiedProperties {
  transferId: string;
}

export interface TransferTypeConfirmedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, link, shop |
   */
  transferType: "email" | "link" | "shop";
}

export class ReferralLinkCopied implements BaseEvent {
  event_type = 'ReferralLinkCopied';

  constructor(
    public event_properties: ReferralLinkCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferCreated implements BaseEvent {
  event_type = 'TransferCreated';

  constructor(
    public event_properties: TransferCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferDownloaded implements BaseEvent {
  event_type = 'TransferDownloaded';

  constructor(
    public event_properties: TransferDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferDownloadRequested implements BaseEvent {
  event_type = 'TransferDownloadRequested';

  constructor(
    public event_properties: TransferDownloadRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferPaymentRequested implements BaseEvent {
  event_type = 'TransferPaymentRequested';

  constructor(
    public event_properties: TransferPaymentRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferSenderVerified implements BaseEvent {
  event_type = 'TransferSenderVerified';

  constructor(
    public event_properties: TransferSenderVerifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransferTypeConfirmed implements BaseEvent {
  event_type = 'TransferTypeConfirmed';

  constructor(
    public event_properties: TransferTypeConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserOnboardStarted implements BaseEvent {
  event_type = 'UserOnboardStarted';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * ReferralLinkCopied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest/ReferralLinkCopied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. referralCode)
   * @param options Amplitude event options.
   */
  referralLinkCopied(
    properties: ReferralLinkCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReferralLinkCopied(properties), options);
  }

  /**
   * TransferCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest/TransferCreated)
   *
   * Owner: Adriano Di Giovanni
   *
   * @param properties The event's properties (e.g. transferExpiresAfter)
   * @param options Amplitude event options.
   */
  transferCreated(
    properties: TransferCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferCreated(properties), options);
  }

  /**
   * TransferDownloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest/TransferDownloaded)
   *
   * Owner: Adriano Di Giovanni
   *
   * @param properties The event's properties (e.g. transferId)
   * @param options Amplitude event options.
   */
  transferDownloaded(
    properties: TransferDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferDownloaded(properties), options);
  }

  /**
   * TransferDownloadRequested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest/TransferDownloadRequested)
   *
   * Owner: Adriano Di Giovanni
   *
   * @param properties The event's properties (e.g. transferId)
   * @param options Amplitude event options.
   */
  transferDownloadRequested(
    properties: TransferDownloadRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferDownloadRequested(properties), options);
  }

  /**
   * TransferPaymentRequested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest/TransferPaymentRequested)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. transferId)
   * @param options Amplitude event options.
   */
  transferPaymentRequested(
    properties: TransferPaymentRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferPaymentRequested(properties), options);
  }

  /**
   * TransferSenderVerified
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest/TransferSenderVerified)
   *
   * Owner: Adriano Di Giovanni
   *
   * @param properties The event's properties (e.g. transferId)
   * @param options Amplitude event options.
   */
  transferSenderVerified(
    properties: TransferSenderVerifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferSenderVerified(properties), options);
  }

  /**
   * TransferTypeConfirmed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest/TransferTypeConfirmed)
   *
   * Owner: Adriano Di Giovanni
   *
   * @param properties The event's properties (e.g. transferType)
   * @param options Amplitude event options.
   */
  transferTypeConfirmed(
    properties: TransferTypeConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransferTypeConfirmed(properties), options);
  }

  /**
   * UserOnboardStarted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fulltransfer/FullTransfer/events/main/latest/UserOnboardStarted)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  userOnboardStarted(
    options?: EventOptions,
  ) {
    return this.track(new UserOnboardStarted(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
