import {Injectable} from '@angular/core';
import {environment} from '@app/env/environment';
import {Ampli, ampli} from './generated';
import {
  Observable,
  ReplaySubject,
  Subject,
  firstValueFrom,
  map,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService extends Ampli {
  loaded$: ReplaySubject<boolean> = new ReplaySubject(1);

  async enableTracking(): Promise<void> {
    try {
      await this.load({...environment.amplitude.loadOptions, disabled: false})
        .promise;
      this.client?.setOptOut(false);
      if (environment.amplitude.sessionReply) {
        const sessionReplayTracking = sessionReplayPlugin(
          environment.amplitude.sessionReply
        );
        await this.client?.add(sessionReplayTracking).promise;
      }
      this.loaded$.next(true);
    } catch (e) {
      this.loaded$.next(false);
    }
  }
  async disableTracking(): Promise<void> {
    try {
      if (this.isLoaded) {
        this.client?.setOptOut(true);
        await this.load({...environment.amplitude.loadOptions, disabled: true})
          .promise;
      }
    } catch (e) {}
    this.loaded$.next(false);
  }

  reset(): void {
    this.isLoaded && this.client?.reset();
  }

  deviceId(): Observable<string | undefined> {
    return this.loaded$.pipe(
      take(1),
      map(
        (loaded) =>
          (loaded && this.isLoaded && this.client?.getDeviceId()) || undefined
      )
    );
  }
}
